import React from "react";

import {
    Container,
    Box,
    Button,
    Typography,
    // Customizable Area Start
    // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider, withStyles } from "@material-ui/core/styles";
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { Transport, InheritanceGiftList, DropdownViewForList, NextPreBtns } from "../../../components/src/CommonCustomForms";
import CustomLeaveGiftTextField from "../../../components/src/CustomLeaveGiftTextField";
import CustomPhoneNumField from "../../../components/src/CustomPhoneNumField";

export const l_theme = createTheme({
    palette: {
        primary: {
            main: "#fff",
            contrastText: "#fff",
        },
    },
    typography: {
        h6: {
            fontWeight: 500,
        },
        subtitle1: {
            margin: "20px 0px",
        },
    },
});
// Customizable Area End

import CustomForm2Controller, {
    Props,
} from "./CustomForm2Controller";

export class LeaveaGift extends CustomForm2Controller {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    showErrorMessage = (errorType: string): JSX.Element => {
        return (
            (errorType !== "" && errorType !== "+44") ? <div className={this.props.classes.errorTextStyle}>{errorType}</div> : <></>
        )
    }
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { classes,
            nextBtnHandler,
            nextErrorBtnHandlerLeaveGift,
            LeaveGiftStateData,
            LeaveGiftHandler,
            LeaveGiftDataError,
            addGiftMoneyHandler,
            removeGiftsMoneyHandler,
            LeaveGiftMoneyListHandler,
            addGiftsHandler,
            removeGiftsHandler,
            LeaveGiftListHandler,
            addRecipientHandler,
            removeRecipientHandler,
            LeaveRecipientListHandler,
            LeavePropertyListHandler,
            addProperty,
            removeProperty } = this.props
        // Customizable Area End
        return (
            // Customizable Area Start
            <ThemeProvider theme={l_theme}>
                <Container maxWidth={"md"}>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                        <form>
                            <Grid container spacing={2} >
                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <Grid container spacing={2} className={classes.styleContainerLeaveGift}>
                                        <Grid item lg={12} md={12} sm={12} xs={12}>
                                            <Typography className={classes.headlineColor}>
                                                Do you want to leave any gifts of money?
                                            </Typography>
                                            <FormControl variant="filled" className={classes.formControlCustomStyleLeaveGift}>
                                                <StyledSelectFieldLeaveGift
                                                    disableUnderline
                                                    renderValue={(selected: React.ChangeEvent<HTMLInputElement>) => {
                                                        return selected;
                                                    }}
                                                    value={LeaveGiftStateData.leave_gift_of_money}
                                                    name="leave_gift_of_money"
                                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => { LeaveGiftHandler?.(event) }}
                                                    data-test-id="leave_gift_of_money">
                                                    <MenuItem value={"Yes"}>Yes</MenuItem>
                                                    <MenuItem value={"No"}>No</MenuItem>
                                                </StyledSelectFieldLeaveGift>
                                            </FormControl>
                                            <Box className={classes.errorMessage}>
                                                {this.showErrorMessage(LeaveGiftDataError?.leave_gift_of_money)}
                                            </Box>
                                        </Grid>
                                        {LeaveGiftStateData.leave_gift_of_money === "Yes" ?
                                            LeaveGiftStateData.giftMoneyList?.map((item: { [key: string]: string }, index: number) => {
                                                return (
                                                    <>
                                                        <Grid key={index} item lg={12} md={12} sm={12} xs={12}
                                                            style={{
                                                                padding: "8px 8px 0px 8px !important"
                                                            }}>
                                                            <Box
                                                                style={{
                                                                    display: "flex",
                                                                    justifyContent: "space-between"
                                                                }}>
                                                                <Typography
                                                                    className={classes.giftHeading}>
                                                                    Gift {1 + index}
                                                                </Typography>
                                                                {LeaveGiftStateData.giftMoneyList.length !== 1 &&
                                                                    <Button
                                                                        style={{
                                                                            textTransform: "none",
                                                                            float: "inline-end",
                                                                            fontSize: "medium",
                                                                            fontWeight: "initial",
                                                                            color: "red"
                                                                        }}
                                                                        onClick={() => { removeGiftsMoneyHandler?.(index) }}
                                                                        data-test-id="removeBtn">
                                                                        Remove
                                                                    </Button>
                                                                }
                                                            </Box>
                                                        </Grid>

                                                        <CustomLeaveGiftTextField
                                                            label="Name"
                                                            value={item['giftMoney' + (index + 1) + '_name']}
                                                            name={`giftMoney${index + 1}_name`}
                                                            index={index}
                                                            inputStyle={classes.input}
                                                            textFieldStyle={classes.textCustomStyle}
                                                            onChangeHandler={LeaveGiftMoneyListHandler}
                                                            dataTestId="giftMoney1_name"
                                                            errorMessageStyle={classes.errorMessage}
                                                            errorMsg={this.showErrorMessage(LeaveGiftDataError?.giftMoneyList[index]['giftMoney' + (index + 1) + '_name'])}
                                                        />

                                                        <CustomLeaveGiftTextField
                                                            label="Address"
                                                            value={item['giftMoney' + (index + 1) + '_address']}
                                                            name={`giftMoney${index + 1}_address`}
                                                            index={index}
                                                            textFieldStyle={classes.textCustomStyle}
                                                            onChangeHandler={LeaveGiftMoneyListHandler}
                                                            dataTestId="giftMoney1_address"
                                                            errorMessageStyle={classes.errorMessage}
                                                            errorMsg={this.showErrorMessage(LeaveGiftDataError?.giftMoneyList[index]['giftMoney' + (index + 1) + '_address'])}
                                                        />

                                                        <Grid item lg={6} md={6} sm={6} xs={12}>
                                                            <StyledTextFieldLeaveGift
                                                                label="Date of Birth"
                                                                value={item['giftMoney' + (index + 1) + '_dob']}
                                                                InputProps={{
                                                                    maxLength: 10,
                                                                    pattern: "\\d{2}/\\d{2}/\\d{4}", // Input pattern for validation
                                                                    title: "Enter a valid date in MM/DD/YYYY format", // Error message for invalid input
                                                                    disableUnderline: true,
                                                                }}
                                                                fullWidth
                                                                id="standard-textarea"
                                                                name={`giftMoney${index + 1}_dob`}
                                                                type="date"
                                                                InputLabelProps={{
                                                                    style: { color: "rgba(0, 0, 0, 0.54)" },
                                                                }}
                                                                className={classes.textCustomStyle}
                                                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => { LeaveGiftMoneyListHandler?.(event, index) }}
                                                                data-test-id="giftMoney1_dob"
                                                            />
                                                            <Box className={classes.errorMessage}>
                                                                {this.showErrorMessage(LeaveGiftDataError?.giftMoneyList[index]['giftMoney' + (index + 1) + '_dob'])}
                                                            </Box>
                                                        </Grid>

                                                        <CustomLeaveGiftTextField
                                                            label="Who would you like to leave the gift to?"
                                                            value={item['define_you_leave_giftMoney' + (index + 1) + '_to']}
                                                            name={`define_you_leave_giftMoney${index + 1}_to`}
                                                            index={index}
                                                            textFieldStyle={classes.textCustomStyle}
                                                            onChangeHandler={LeaveGiftMoneyListHandler}
                                                            dataTestId="define_you_leave_giftMoney1_to"
                                                            errorMessageStyle={classes.errorMessage}
                                                            errorMsg={this.showErrorMessage(LeaveGiftDataError?.giftMoneyList[index]['define_you_leave_giftMoney' + (index + 1) + '_to'])}
                                                        />

                                                        <CustomPhoneNumField
                                                            PhoneNumberGridStyle={classes.PhoneNumberGrid}
                                                            value={item['giftMoney' + (index + 1) + '_phone_number']}
                                                            name={`giftMoney${index + 1}_phone_number`}
                                                            index={index}
                                                            textFieldStyle={classes.textCustomStyleMobile}
                                                            onChangeHandler={LeaveGiftMoneyListHandler}
                                                            dataTestId="giftMoney1_phone_number"
                                                            errorMsg={this.showErrorMessage(LeaveGiftDataError?.giftMoneyList[index]['giftMoney' + (index + 1) + '_phone_number'])}
                                                            errorMessageStyle={classes.errorMessage}
                                                            countryCodeInput={item['giftMoney' + (index + 1) + '_country_code']}
                                                            countryCodeNameInput={`giftMoney${index + 1}_country_code`}
                                                            propsValue={this.props}
                                                            CountryList={this.state.CountryList}
                                                        />

                                                        <CustomLeaveGiftTextField
                                                            label="Email"
                                                            value={item['giftMoney' + (index + 1) + '_email']}
                                                            name={`giftMoney${index + 1}_email`}
                                                            index={index}
                                                            inputType={"email"}
                                                            textFieldStyle={classes.textCustomStyle}
                                                            onChangeHandler={LeaveGiftMoneyListHandler}
                                                            dataTestId="giftMoney1_email"
                                                            errorMessageStyle={classes.errorMessage}
                                                            errorMsg={this.showErrorMessage(LeaveGiftDataError?.giftMoneyList[index]['giftMoney' + (index + 1) + '_email'])}
                                                        />

                                                        <Grid item lg={12} md={12} sm={12} xs={12} className={classes.addBtnContainer}>
                                                            <Button
                                                                style={{
                                                                    textTransform: "none",
                                                                    fontSize: "medium",
                                                                    fontWeight: "initial",
                                                                    color: "#000 !important"
                                                                }}
                                                                onClick={() => { addGiftMoneyHandler?.() }}
                                                            >
                                                                +Add
                                                            </Button>
                                                        </Grid>
                                                        <Grid item lg={12} md={12} sm={12} xs={12}>
                                                            <hr style={{ opacity: 0.1 }} />
                                                        </Grid>
                                                    </>
                                                )
                                            }) : null
                                        }
                                        <Grid item lg={12} md={12} sm={12} xs={12}>
                                            <Typography className={classes.headlineColor}>
                                                Do you want to leave any specific gifts?
                                            </Typography>
                                            <FormControl variant="filled" className={classes.formControlCustomStyleLeaveGift}>
                                                <StyledSelectFieldLeaveGift
                                                    disableUnderline
                                                    renderValue={(selected: React.ChangeEvent<HTMLInputElement>) => {
                                                        return selected;
                                                    }}
                                                    value={LeaveGiftStateData.leave_any_specific_gift}
                                                    name="leave_any_specific_gift"
                                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => { LeaveGiftHandler?.(e) }}
                                                    data-test-id="leave_any_specific_gift">
                                                    <MenuItem value={"Yes"}>Yes</MenuItem>
                                                    <MenuItem value={"No"}>No</MenuItem>
                                                </StyledSelectFieldLeaveGift>
                                            </FormControl>
                                            <Box className={classes.errorMessage}>
                                                {this.showErrorMessage(LeaveGiftDataError?.leave_any_specific_gift)}
                                            </Box>
                                        </Grid>
                                        {LeaveGiftStateData.leave_any_specific_gift === "Yes" ?
                                            LeaveGiftStateData.giftList?.map((item: { [key: string]: string }, index: number) => {
                                                return (
                                                    <>
                                                        <Grid key={index} item lg={12} md={12} sm={12} xs={12}
                                                            style={{
                                                                padding: "8px 8px 0px 8px !important"
                                                            }}>
                                                            <Box
                                                                style={{
                                                                    display: "flex",
                                                                    justifyContent: "space-between"
                                                                }}>
                                                                <Typography
                                                                    className={classes.giftHeading}>
                                                                    Gift {1 + index}
                                                                </Typography>
                                                                {LeaveGiftStateData.giftList.length !== 1 &&
                                                                    <Button
                                                                        style={{
                                                                            textTransform: "none",
                                                                            float: "inline-end",
                                                                            fontSize: "medium",
                                                                            fontWeight: "initial",
                                                                            color: "red"
                                                                        }}
                                                                        onClick={() => { removeGiftsHandler?.(index) }}
                                                                        data-test-id="removeBtn">
                                                                        Remove
                                                                    </Button>
                                                                }
                                                            </Box>
                                                        </Grid>
                                                        <CustomLeaveGiftTextField
                                                            label="What is the Gift?"
                                                            value={item['what_is_gift' + (index + 1)]}
                                                            name={`what_is_gift${index + 1}`}
                                                            index={index}
                                                            inputStyle={classes.input}
                                                            textFieldStyle={classes.textCustomStyle}
                                                            onChangeHandler={LeaveGiftListHandler}
                                                            dataTestId="what_is_gift1"
                                                            errorMessageStyle={classes.errorMessage}
                                                            errorMsg={this.showErrorMessage(LeaveGiftDataError?.giftList[index]['what_is_gift' + (index + 1)])}
                                                        />

                                                        <CustomLeaveGiftTextField
                                                            label="Who would you like to leave the gift to?"
                                                            value={item['define_you_leave_gift' + (index + 1) + '_to']}
                                                            name={`define_you_leave_gift${index + 1}_to`}
                                                            index={index}
                                                            textFieldStyle={classes.textCustomStyle}
                                                            onChangeHandler={LeaveGiftListHandler}
                                                            dataTestId="define_you_leave_gift1_to"
                                                            errorMessageStyle={classes.errorMessage}
                                                            errorMsg={this.showErrorMessage(LeaveGiftDataError?.giftList[index]['define_you_leave_gift' + (index + 1) + '_to'])}
                                                        />

                                                        <CustomLeaveGiftTextField
                                                            label="Name"
                                                            value={item['gift' + (index + 1) + '_name']}
                                                            name={`gift${index + 1}_name`}
                                                            index={index}
                                                            inputStyle={classes.input}
                                                            textFieldStyle={classes.textCustomStyle}
                                                            onChangeHandler={LeaveGiftListHandler}
                                                            dataTestId="gift1_name"
                                                            errorMessageStyle={classes.errorMessage}
                                                            errorMsg={this.showErrorMessage(LeaveGiftDataError?.giftList[index]['gift' + (index + 1) + '_name'])}
                                                        />

                                                        <CustomLeaveGiftTextField
                                                            label="Address"
                                                            value={item['gift' + (index + 1) + '_address']}
                                                            name={`gift${index + 1}_address`}
                                                            index={index}
                                                            inputStyle={classes.input}
                                                            textFieldStyle={classes.textCustomStyle}
                                                            onChangeHandler={LeaveGiftListHandler}
                                                            dataTestId="gift1_address"
                                                            errorMessageStyle={classes.errorMessage}
                                                            errorMsg={this.showErrorMessage(LeaveGiftDataError?.giftList[index]['gift' + (index + 1) + '_address'])}
                                                        />

                                                        <CustomPhoneNumField
                                                            PhoneNumberGridStyle={classes.PhoneNumberGrid}
                                                            value={item['gift' + (index + 1) + '_phone_number']}
                                                            name={`gift${index + 1}_phone_number`}
                                                            index={index}
                                                            textFieldStyle={classes.textCustomStyleMobile}
                                                            onChangeHandler={LeaveGiftListHandler}
                                                            dataTestId="gift1_phone_number"
                                                            errorMsg={this.showErrorMessage(LeaveGiftDataError?.giftList[index]['gift' + (index + 1) + '_phone_number'])}
                                                            errorMessageStyle={classes.errorMessage}
                                                            countryCodeInput={item['gift' + (index + 1) + '_country_code']}
                                                            countryCodeNameInput={`gift${index + 1}_country_code`}
                                                            propsValue={this.props}
                                                            CountryList={this.state.CountryList}
                                                        />


                                                        <CustomLeaveGiftTextField
                                                            label="Email"
                                                            value={item['gift' + (index + 1) + '_email']}
                                                            name={`gift${index + 1}_email`}
                                                            index={index}
                                                            inputType="email"
                                                            textFieldStyle={classes.textCustomStyle}
                                                            onChangeHandler={LeaveGiftListHandler}
                                                            dataTestId="gift1_email"
                                                            errorMessageStyle={classes.errorMessage}
                                                            errorMsg={this.showErrorMessage(LeaveGiftDataError?.giftList[index]['gift' + (index + 1) + '_email'])}
                                                        />

                                                        <Grid item lg={12} md={12} sm={12} xs={12}>
                                                            <Grid container spacing={2} className={classes.styleContainerLeaveGift}>
                                                                {Transport({
                                                                    PropValues: this.props,
                                                                    LeaveGiftListHandler: LeaveGiftListHandler,
                                                                    LeaveGiftDataError: LeaveGiftDataError,
                                                                    showErrorMessageHandler: this.showErrorMessage,
                                                                    transportStateValue: item['gift' + (index + 1) + '_transportation_cost'],
                                                                    transportStateName: `gift${index + 1}_transportation_cost`,
                                                                    index: index
                                                                })
                                                                }
                                                                {
                                                                    InheritanceGiftList({
                                                                        PropValues: this.props,
                                                                        LeaveGiftListHandler: LeaveGiftListHandler,
                                                                        LeaveGiftDataErrorMessage: LeaveGiftDataError?.giftList[index].gift1_inheritance_cost,
                                                                        showErrorMessageHandler: this.showErrorMessage,
                                                                        inheritanceStateValue: item['gift' + (index + 1) + '_inheritance_cost'],
                                                                        inheritanceStateName: `gift${index + 1}_inheritance_cost`,
                                                                        index: index
                                                                    })
                                                                }
                                                            </Grid>
                                                        </Grid>
                                                        <Grid item lg={12} md={12} sm={12} xs={12} className={classes.addBtnContainer}>
                                                            <Button
                                                                style={{
                                                                    textTransform: "none",
                                                                    fontSize: "medium",
                                                                    fontWeight: "initial",
                                                                    color: "#000 !important"
                                                                }}
                                                                onClick={() => { addGiftsHandler?.() }}
                                                            >
                                                                +Add
                                                            </Button>
                                                        </Grid>
                                                        <Grid item lg={12} md={12} sm={12} xs={12}>
                                                            <hr style={{ opacity: 0.1 }} />
                                                        </Grid>
                                                    </>
                                                )
                                            }) : null
                                        }
                                    </Grid>
                                </Grid>
                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <Typography className={classes.headlineColor}>
                                        Do you want to leave any gifts of property (not in a property trust)?
                                    </Typography>
                                    <FormControl variant="filled" className={classes.formControlCustomStyleLeaveGift}>
                                        <StyledSelectFieldLeaveGift
                                            disableUnderline
                                            renderValue={(selected: React.ChangeEvent<HTMLInputElement>) => {
                                                return selected;
                                            }}
                                            value={LeaveGiftStateData.leave_gift_of_property}
                                            name="leave_gift_of_property"
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => { LeaveGiftHandler?.(e) }}
                                            data-test-id="leave_gift_of_property">
                                            <MenuItem value={"Yes"}>Yes</MenuItem>
                                            <MenuItem value={"No"}>No</MenuItem>
                                        </StyledSelectFieldLeaveGift>
                                    </FormControl>
                                    <Box className={classes.errorMessage}>
                                        {this.showErrorMessage(LeaveGiftDataError?.leave_gift_of_property)}
                                    </Box>
                                </Grid>
                                {LeaveGiftStateData.leave_gift_of_property === "Yes" ?
                                    LeaveGiftStateData.propertyPersonsList?.map((item: { [key: string]: string }, index: number) => {
                                        return (
                                            <>
                                                <Grid key={index} item lg={12} md={12} sm={12} xs={12}>
                                                    <Box
                                                        style={{
                                                            display: "flex",
                                                            justifyContent: "space-between"
                                                        }}>
                                                        <Typography
                                                            className={classes.giftHeading}>
                                                            Person {1 + index}
                                                        </Typography>
                                                        {LeaveGiftStateData.propertyPersonsList.length !== 1 &&
                                                            <Button
                                                                style={{
                                                                    textTransform: "none",
                                                                    float: "inline-end",
                                                                    fontSize: "medium",
                                                                    fontWeight: "initial",
                                                                    color: "red"
                                                                }}
                                                                type="submit"
                                                                onClick={() => { removeProperty?.(index) }}>
                                                                Remove
                                                            </Button>
                                                        }
                                                    </Box>
                                                </Grid>

                                                <CustomLeaveGiftTextField
                                                    label="Name"
                                                    value={item['gift_of_property' + (index + 1) + '_name']}
                                                    name={`gift_of_property${index + 1}_name`}
                                                    index={index}
                                                    inputStyle={classes.input}
                                                    textFieldStyle={classes.textCustomStyle}
                                                    onChangeHandler={LeavePropertyListHandler}
                                                    dataTestId="gift_of_property1_name"
                                                    errorMessageStyle={classes.errorMessage}
                                                    errorMsg={this.showErrorMessage(LeaveGiftDataError?.propertyPersonsList[index]['gift_of_property' + (index + 1) + '_name'])}
                                                />
                                                <CustomLeaveGiftTextField
                                                    label="Address"
                                                    value={item['gift_of_property' + (index + 1) + '_address']}
                                                    name={`gift_of_property${index + 1}_address`}
                                                    index={index}
                                                    textFieldStyle={classes.textCustomStyle}
                                                    onChangeHandler={LeavePropertyListHandler}
                                                    dataTestId="gift_of_property1_address"
                                                    errorMessageStyle={classes.errorMessage}
                                                    errorMsg={this.showErrorMessage(LeaveGiftDataError?.propertyPersonsList[index]['gift_of_property' + (index + 1) + '_address'])}
                                                />

                                                <CustomPhoneNumField
                                                    PhoneNumberGridStyle={classes.PhoneNumberGrid}
                                                    value={item['gift_of_property' + (index + 1) + '_phone_number']}
                                                    name={`gift_of_property${index + 1}_phone_number`}
                                                    index={index}
                                                    textFieldStyle={classes.textCustomStyleMobile}
                                                    onChangeHandler={LeavePropertyListHandler}
                                                    dataTestId="gift_of_property1_phone_number"
                                                    errorMsg={this.showErrorMessage(LeaveGiftDataError?.propertyPersonsList[index]['gift_of_property' + (index + 1) + '_phone_number'])}
                                                    errorMessageStyle={classes.errorMessage}
                                                    countryCodeInput={item['gift_of_property' + (index + 1) + '_country_code']}
                                                    countryCodeNameInput={"gift_of_property" + (index + 1) + "_country_code"}
                                                    propsValue={this.props}
                                                    CountryList={this.state.CountryList}
                                                />

                                                <CustomLeaveGiftTextField
                                                    label="Email"
                                                    value={item['gift_of_property' + (index + 1) + '_email']}
                                                    name={`gift_of_property${index + 1}_email`}
                                                    index={index}
                                                    inputType="email"
                                                    textFieldStyle={classes.textCustomStyle}
                                                    onChangeHandler={LeavePropertyListHandler}
                                                    dataTestId="gift_of_property1_email"
                                                    errorMessageStyle={classes.errorMessage}
                                                    errorMsg={this.showErrorMessage(LeaveGiftDataError?.propertyPersonsList[index]['gift_of_property' + (index + 1) + '_email'])}
                                                />

                                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                                    <Grid container spacing={2} className={classes.styleContainerLeaveGift}>
                                                        {
                                                            InheritanceGiftList({
                                                                PropValues: this.props,
                                                                LeaveGiftListHandler: LeavePropertyListHandler,
                                                                LeaveGiftDataErrorMessage: LeaveGiftDataError?.propertyPersonsList[index]['gift_of_property' + (index + 1) + '_inheritance_tax_be_paid'],
                                                                showErrorMessageHandler: this.showErrorMessage,
                                                                inheritanceStateValue: item['gift_of_property' + (index + 1) + '_inheritance_tax_be_paid'],
                                                                inheritanceStateName: "gift_of_property" + (index + 1) + "_inheritance_tax_be_paid",
                                                                index: index
                                                            })
                                                        }
                                                        <Grid item lg={6} md={6} sm={6} xs={12}>
                                                            <Typography className={classes.headlineColor}>
                                                                How shall Expenses be paid
                                                            </Typography>
                                                            <FormControl variant="filled" className={classes.formControlCustomStyleLeaveGift}>
                                                                <StyledSelectFieldLeaveGift
                                                                    disableUnderline
                                                                    renderValue={(selected: React.ChangeEvent<HTMLInputElement>) => {
                                                                        return selected;
                                                                    }}
                                                                    value={item['gift_of_property' + (index + 1) + '_expenses_be_paid']}
                                                                    name={`gift_of_property${index + 1}_expenses_be_paid`}
                                                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => { LeavePropertyListHandler?.(event, index) }}
                                                                    data-test-id="gift_of_property1_expenses_be_paid">
                                                                    <MenuItem value={"Expenses / mortgage to be paid by the estate"}>Expenses / mortgage to be paid by the estate</MenuItem>
                                                                    <MenuItem value={"Expenses / mortgage to be paid by the recipient"}>Expenses / mortgage to be paid by the recipient</MenuItem>
                                                                </StyledSelectFieldLeaveGift>
                                                            </FormControl>
                                                            <Box className={classes.errorMessage}>
                                                                {this.showErrorMessage(LeaveGiftDataError?.propertyPersonsList[index]['gift_of_property' + (index + 1) + '_expenses_be_paid'])}
                                                            </Box>
                                                        </Grid>

                                                        <Grid item lg={6} md={6} sm={6} xs={12}>
                                                            <Typography className={classes.headlineColor}>
                                                                Also include any main residence?
                                                            </Typography>
                                                            <FormControl variant="filled" className={classes.formControlCustomStyleLeaveGift}>
                                                                <StyledSelectFieldLeaveGift
                                                                    disableUnderline
                                                                    renderValue={(selected: React.ChangeEvent<HTMLInputElement>) => {
                                                                        return selected;
                                                                    }}
                                                                    value={item['gift_of_property' + (index + 1) + '_include_any_main_residence']}
                                                                    name={`gift_of_property${index + 1}_include_any_main_residence`}
                                                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => { LeavePropertyListHandler?.(event, index) }}
                                                                    data-test-id="gift_of_property1_include_any_main_residence">
                                                                    <MenuItem value={"No"}>No</MenuItem>
                                                                    <MenuItem value={"Yes"}>Yes</MenuItem>
                                                                </StyledSelectFieldLeaveGift>
                                                            </FormControl>
                                                            <Box className={classes.errorMessage}>
                                                                {this.showErrorMessage(LeaveGiftDataError?.propertyPersonsList[index]['gift_of_property' + (index + 1) + '_include_any_main_residence'])}
                                                            </Box>
                                                        </Grid>
                                                        <Grid item lg={6} md={6} sm={6} xs={12}>
                                                            <Typography className={classes.headlineColor}>
                                                                Include property contents?
                                                            </Typography>
                                                            <FormControl variant="filled" className={classes.formControlCustomStyleLeaveGift}>
                                                                <StyledSelectFieldLeaveGift
                                                                    disableUnderline
                                                                    renderValue={(selected: React.ChangeEvent<HTMLInputElement>) => {
                                                                        return selected;
                                                                    }}
                                                                    value={item['gift_of_property' + (index + 1) + '_include_property_content']}
                                                                    name={`gift_of_property${index + 1}_include_property_content`}
                                                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => { LeavePropertyListHandler?.(event, index) }}
                                                                    data-test-id="gift_of_property1_include_property_content">
                                                                    <MenuItem value={"No"}>No</MenuItem>
                                                                    <MenuItem value={"Yes"}>Yes</MenuItem>
                                                                </StyledSelectFieldLeaveGift>
                                                            </FormControl>
                                                            <Box className={classes.errorMessage}>
                                                                {this.showErrorMessage(LeaveGiftDataError?.propertyPersonsList[index]['gift_of_property' + (index + 1) + '_include_property_content'])}
                                                            </Box>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item lg={12} md={12} sm={12} xs={12} className={classes.addBtnContainer}>
                                                    <Button
                                                        style={{
                                                            textTransform: "none",
                                                            fontSize: "medium",
                                                            fontWeight: "initial",
                                                            color: "#000 !important"
                                                        }}
                                                        onClick={() => { addProperty?.() }}
                                                    >
                                                        +Add
                                                    </Button>
                                                </Grid>
                                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                                    <hr style={{ opacity: 0.1 }} />
                                                </Grid>
                                            </>
                                        )
                                    })
                                    : null

                                }
                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <Typography className={classes.headlineColor}>
                                        What should happen to personal possessions not specified by this Will?
                                    </Typography>
                                    <FormControl variant="filled" className={classes.formControlCustomStyleLeaveGift}>
                                        <StyledSelectFieldLeaveGift
                                            disableUnderline
                                            renderValue={(selected: React.ChangeEvent<HTMLInputElement>) => {
                                                return selected;
                                            }}
                                            value={LeaveGiftStateData.define_when_personal_possession_not_specify}
                                            name="define_when_personal_possession_not_specify"
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => { LeaveGiftHandler?.(e) }}
                                            data-test-id="define_when_personal_possession_not_specify">
                                            <MenuItem value={"Not Applicable"}>Not Applicable</MenuItem>
                                            <MenuItem value={"Pass to specific recipient"}>Pass to specific recipient</MenuItem>
                                            <MenuItem value={"Distributed by Executors"}>Distributed by Executors</MenuItem>
                                        </StyledSelectFieldLeaveGift>
                                    </FormControl>
                                    <Box className={classes.errorMessage}>
                                        {this.showErrorMessage(LeaveGiftDataError?.define_when_personal_possession_not_specify)}
                                    </Box>
                                </Grid>

                                {LeaveGiftStateData.define_when_personal_possession_not_specify === "Pass to specific recipient" ?
                                    LeaveGiftStateData.recipientList?.map((item: { [key: string]: string }, index: number) => {
                                        return (
                                            <>
                                                <Grid key={index} item lg={12} md={12} sm={12} xs={12}
                                                    style={{
                                                        padding: "8px 8px 0px 8px !important"
                                                    }}>
                                                    <Box
                                                        style={{
                                                            display: "flex",
                                                            justifyContent: "space-between"
                                                        }}>
                                                        <Typography
                                                            className={classes.giftHeading}>
                                                            Recipient {1 + index}
                                                        </Typography>
                                                        {LeaveGiftStateData.recipientList.length !== 1 &&
                                                            <Button
                                                                style={{
                                                                    textTransform: "none",
                                                                    float: "inline-end",
                                                                    fontSize: "medium",
                                                                    fontWeight: "initial",
                                                                    color: "red"
                                                                }}
                                                                onClick={() => { removeRecipientHandler?.(index) }}
                                                                data-test-id="removeBtn">
                                                                Remove
                                                            </Button>
                                                        }
                                                    </Box>
                                                </Grid>

                                                <CustomLeaveGiftTextField
                                                    label="Name"
                                                    value={item['recipient' + (index + 1) + '_name']}
                                                    name={`recipient${index + 1}_name`}
                                                    index={index}
                                                    inputStyle={classes.input}
                                                    textFieldStyle={classes.textCustomStyle}
                                                    onChangeHandler={LeaveRecipientListHandler}
                                                    dataTestId="recipient1_name"
                                                    errorMessageStyle={classes.errorMessage}
                                                    errorMsg={this.showErrorMessage(LeaveGiftDataError?.recipientList[index]['recipient' + (index + 1) + '_name'])}
                                                />

                                                <CustomLeaveGiftTextField
                                                    label="Address"
                                                    value={item['recipient' + (index + 1) + '_address']}
                                                    name={`recipient${index + 1}_address`}
                                                    index={index}
                                                    textFieldStyle={classes.textCustomStyle}
                                                    onChangeHandler={LeaveRecipientListHandler}
                                                    dataTestId="recipient1_address"
                                                    errorMessageStyle={classes.errorMessage}
                                                    errorMsg={this.showErrorMessage(LeaveGiftDataError?.recipientList[index]['recipient' + (index + 1) + '_address'])}
                                                />

                                                <CustomPhoneNumField
                                                    PhoneNumberGridStyle={classes.PhoneNumberGrid}
                                                    value={item['recipient' + (index + 1) + '_phone_number']}
                                                    name={`recipient${index + 1}_phone_number`}
                                                    index={index}
                                                    textFieldStyle={classes.textCustomStyleMobile}
                                                    onChangeHandler={LeaveRecipientListHandler}
                                                    dataTestId="recipient1_phone_number"
                                                    errorMsg={this.showErrorMessage(LeaveGiftDataError?.recipientList[index]['recipient' + (index + 1) + '_phone_number'])}
                                                    errorMessageStyle={classes.errorMessage}
                                                    countryCodeInput={item['recipient' + (index + 1) + '_country_code']}
                                                    countryCodeNameInput={"recipient" + (index + 1) + "_country_code"}
                                                    propsValue={this.props}
                                                    CountryList={this.state.CountryList}
                                                />

                                                <CustomLeaveGiftTextField
                                                    label="Email"
                                                    value={item['recipient' + (index + 1) + '_email']}
                                                    name={`recipient${index + 1}_email`}
                                                    inputType="email"
                                                    index={index}
                                                    textFieldStyle={classes.textCustomStyle}
                                                    onChangeHandler={LeaveRecipientListHandler}
                                                    dataTestId="recipient1_email"
                                                    errorMessageStyle={classes.errorMessage}
                                                    errorMsg={this.showErrorMessage(LeaveGiftDataError?.recipientList[index]['recipient' + (index + 1) + '_email'])}
                                                />
                                                <Grid item lg={12} md={12} sm={12} xs={12} className={classes.addBtnContainer}>
                                                    <Button
                                                        style={{
                                                            textTransform: "none",
                                                            fontSize: "medium",
                                                            fontWeight: "initial",
                                                            color: "#000 !important"
                                                        }}
                                                        onClick={() => { addRecipientHandler?.() }}
                                                    >
                                                        +Add
                                                    </Button>
                                                </Grid>
                                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                                    <hr style={{ opacity: 0.1 }} />
                                                </Grid>
                                            </>
                                        )
                                    }) : null
                                }

                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <Typography className={classes.headlineColor}>
                                        Who shall pay Inheritance Tax?
                                    </Typography>
                                    <FormControl variant="filled" className={classes.formControlCustomStyleLeaveGift}>
                                        <StyledSelectFieldLeaveGift
                                            disableUnderline
                                            renderValue={(selected: React.ChangeEvent<HTMLInputElement>) => {
                                                return selected;
                                            }}
                                            value={LeaveGiftStateData.who_pay_inheritance_tax}
                                            name="who_pay_inheritance_tax"
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => { LeaveGiftHandler?.(e) }}
                                            data-test-id="who_pay_inheritance_tax">
                                            <MenuItem value={"IHT to be paid by the estate"}>IHT to be paid by the estate</MenuItem>
                                            <MenuItem value={"IHT to be paid by the recipient"}>IHT to be paid by the recipient</MenuItem>
                                        </StyledSelectFieldLeaveGift>
                                    </FormControl>
                                    <Box className={classes.errorMessage}>
                                        {this.showErrorMessage(LeaveGiftDataError?.who_pay_inheritance_tax)}
                                    </Box>
                                </Grid>
                                {
                                    NextPreBtns({
                                        PropValues: this.props,
                                        nextBtnHandler: nextBtnHandler,
                                        prevPage: "AppointingGuardian",
                                        nextPage: "ExemptionsToYourWill",
                                        nextErrorBtnHandler: nextErrorBtnHandlerLeaveGift
                                    })
                                }
                            </Grid>
                        </form>
                    </Grid>
                </Container>
            </ThemeProvider>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
export const useStyle_leaveGift = (l_theme: any) => ({

    textCustomStyle: {
        border: "1px solid #D3D3D3",
        padding: "10px"
    },
    textCustomStyleMobile: {
        border: "1px solid #D3D3D3",
        padding: "10px",
        borderRadius: "8px 0px 0px 8px !important"
    },
    headLine: {
        padding: "10px",
        color: "rgba(0, 0, 0, 0.54)"
    },
    btnStyle: {
        backgroundColor: "#364F6B",
        color: "#ffffff",
        width: "20%",
        padding: "10px",
        margin: "8px 8px 26px 8px",
    },
    formControlCustomStyleMobileSingle: {
        display: "flex",
        borderRadius: "8px",
        backgroundColor: "#ffffff !important",
        "& div": {
            borderRadius: "0px 8px 8px 0px !important",
            backgroundColor: "#ffffff !important",
        },
        "& div input": {
            padding: "10px 10px"
        },
        "& div:focus": {
            borderRadius: "8px",
            backgroundColor: "#ffffff !important",
        },
        "& .MuiFilledInput": {
            padding: "27px 12px 1px !important"
        }
    },
    formControlCustomStyleLeaveGift: {
        display: "flex",
        borderRadius: "8px",
        backgroundColor: "#ffffff !important",
        "& div": {
            borderRadius: "8px",
            backgroundColor: "#ffffff !important",
        },
        "& div:focus": {
            borderRadius: "8px",
            backgroundColor: "#ffffff !important",
        }
    },
    styleContainerLeaveGift: {
        alignItems: "flex-end"
    },
    headlineColor: {
        padding: "2% 0",
        color: "#000 !important"
    },
    btnCollection: {
        display: "flex",
        justifyContent: "flex-end",
        marginTop: "5%"
    },
    addBtnContainer: {
        display: "flex",
        justifyContent: "flex-end"
    },
    giftHeading: {
        padding: "10px 10px 5px 10px",
        color: "rgba(0, 0, 0, 0.54)"
    },
    countryCodeContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        paddingRight: "5px"
    },
    countryFlag: {
        borderRadius: "50%",
        height: "25px",
        width: "30px",
        marginRight: "5px"
    },
    PhoneNumberGrid: {
        display: "flex",
        justifyContent: "center",
        alignItems: "flex-start",
    },
    errorTextStyle: {
        color: "red",
        fontSize: "0.9rem"
    },
    errorMessage: {
        height: "1rem",
    }
});

export const StyledTextFieldLeaveGift: any = withStyles({
    root: {
        fontSize: "14",
        borderRadius: "8px",
        border: "1px solid #D3D3D3",
        backgroundColor: "#ffffff !important",
        display: "block !important",
        "& #standard-textarea-label": {
            padding: "0px 0px 0px 10px",
        },
        "& .Mui-focused": {
            padding: "10px 0px 0px 10px !important",
        },
        "& #standard-textarea": {
            color: "#000 !important"
        }

    },
})((props: any) => <TextField {...props} />);

export const StyledSelectFieldLeaveGift: any = withStyles({
    root: {
        border: "1px solid #D3D3D3 !important",
        borderRadius: "8px",
        backgroundColor: "#ffffff !important",
        height: "auto",
        padding: "21.5px 10px !important"
    }
})((props: any) => <Select {...props} />);

export default LeaveaGift;
// Customizable Area End
